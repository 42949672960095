@import 'libs/styles/src/common/common';

@layer override {
  .header {
    font-size: 2.25rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
    }
  }

  .sub-header {
    font-size: 1.5rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
  }

  .terms-header {
    font-size: 1.5rem;

    @include media-breakpoint-down(md) {
      font-size: 1.35rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
}
